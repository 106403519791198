import { APIVersionConfig } from "src/api-versions/api-version-config-qa";

/**
 * @file environnement.qa.ts
 */
export const environment = {
  envName: 'qa',
  production: false,
  hostURL: 'https://api-qa.myempirxhealth.com/api',
  langHostURL: `https://api-qa.myempirxhealth.com/api`,
  // b2cSignUpSignInFlow: 'https://empirxmpb2cqa.b2clogin.com/empirxmpb2cqa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=3a84dac1-ec77-4e3e-98c4-907bc967126f&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  // b2cForgotPassFlow: 'https://empirxmpb2cqa.b2clogin.com/empirxmpb2cqa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=3a84dac1-ec77-4e3e-98c4-907bc967126f&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',

  b2cSignUpSignInFlow: 'https://empirxmpb2cqa.b2clogin.com/empirxmpb2cqa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Gallient_SignUpSignIn&client_id=3a84dac1-ec77-4e3e-98c4-907bc967126f&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',
  b2cForgotPassFlow: 'https://empirxmpb2cqa.b2clogin.com/empirxmpb2cqa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Gallient_PasswordReset&client_id=3a84dac1-ec77-4e3e-98c4-907bc967126f&nonce=defaultNonce&redirect_uri=' + window.location.origin + '&scope=openid&response_type=id_token&prompt=login',

  googleApiKey: 'AIzaSyBfo9a3aDoFj_C9TyCowJa2g7Dq7pVpkSI',
  instrumentationKey: '5684418a-a6cc-41c9-b9cd-26c1f278904b',
  getDocuments: 'https://api-qa.myempirxhealth.com/api/CMSDocker/index.php/wp-json/wp/v2/pages/pdf-download',
  newUrl: 'https://portal-qa.myempirxhealth.com/',
  wpApiToken: '0zflbf70n6vrz5runxcmiwhotvygahyu3rza7nhq',
  ocpApimSubscriptionKey: 'f3b3d532731f4286998eaf5cd0bb8b90',
  apiVersionConfig: APIVersionConfig

};
