/**
 * @file app-insights.ts
 */
export class AppCenterAnalytics {
  public static readonly HOME_VIEWED = 'HOME PAGE VIEWED';
  public static readonly PRESCRIPTIONS_VIEWED = 'PRESCRIPTIONS LIST VIEWED';
  public static readonly PRESCRIPTIONS_DETAILS_VIEWED = 'PRESCRIPTION DETAILS VIEWED';
  public static readonly DRUG_SEARCH_VIEWED = 'DRUG SEARCH VIEWED';
  public static readonly DRUG_PRICING_VIEWED = 'DRUG PRICING VIEWED';
  public static readonly DRUG_PRICING_DETAILS_VIEWED = 'DRUG PRICING DETAILS VIEWED';
  public static readonly DRUG_PRICING_LIST_VIEWED = 'DRUG PRICING LIST VIEWED';
  public static readonly FIND_PHARMACY_VIEWED = 'FIND PHARMACY VIEWED';
  public static readonly FIND_PHARMACY_DETAILS_VIEWED = 'FIND PHARMACY DETAILS VIEWED';
  public static readonly ORDERS_VIEWED = 'ORDERS VIEWED';
  public static readonly ORDERS_DETAILS_VIEWED = 'ORDER DETAILS VIEWED';
  public static readonly CLAIMS_VIEWED = 'CLAIMS VIEWED';
  public static readonly CLAIMS_DETAILS_VIEWED = 'CLAIM DETAILS VIEWED';
  public static readonly MEMBERSHIP_VIEWED = 'FAMILY PROFILE VIEWED';
  public static readonly BENEFITS_MOP_VIEWED = 'BENEFITS MOOP/DEDUCTIBLE VIEWED';
  public static readonly BENEFITS_COPAY_VIEWED = 'BENEFITS COPAY VIEWED';
  public static readonly BENEFITS_MEDICATION_VIEWED = 'BENEFITS MEDICATION LIST VIEWED';
  public static readonly BENEFITS_EOB_VIEWED = 'BENEFITS EOB SCREEN VIEWED';
  public static readonly BENEFITS_VIEWED = 'BENEFITS VIEWED';
  public static readonly PUBLIC_FORMS_VIEWED = 'PUBLIC FORMS VIEWED';
  public static readonly CART_VIEWED = 'CART VIEWED';
  public static readonly CART_CHECKOUT_VIEWED = 'CART CHECKOUT VIEWED';
  public static readonly CART_ADDRESS_VIEWED = 'CART ADDRESS VIEWED';
  public static readonly CART_PAYMENT_VIEWED = 'CART PAYMENT VIEWED';
  public static readonly CART_CONFIRM_VIEWED = 'CART CONFIRMATION VIEWED';
  public static readonly CART_THANK_YOU_VIEWED = 'ORDER PLACED';
  public static readonly CART_ORDER_FAILED_VIEWED = 'ORDER PLACEMENT FAILED';
  public static readonly MAIL_ORDER_FAQs_VIEWED = 'MAIL ORDER FAQs VIEWED';
  public static readonly CLINICAL_REVIEW_FAQs_VIEWED = 'CLINICAL REVIEW FAQs VIEWED';
  public static readonly BENEFITS_FAQs_VIEWED = 'BENEFITS FAQs VIEWED';
  public static readonly ABOUT_US_VIEWED = 'ABOUT US VIEWED';
  public static readonly PRIVACY_POLICY_VIEWED = 'PRIVACY POLICY VIEWED';
  public static readonly TERMS_OF_USE_VIEWED = 'TERMS OF USE VIEWED';
  public static readonly PHI_SETTINGS_VIEWED = 'PHI SETTINGS VIEWED';
  public static readonly NOTIFICATIONS_VIEWED = 'NOTIFICATIONS VIEWED';
  public static readonly ORDER_NOTIFICATIONS_VIEWED = 'ORDER NOTIFICATIONS VIEWED';
  public static readonly CLINICAL_NOTIFICATIONS_VIEWED = 'CLINICAL NOTIFICATIONS VIEWED';
  public static readonly TEMP_CARD_VIEWED = 'TEMP CARD VIEWED';
  public static readonly ORDER_HISTORY_VIEWED = 'ORDER HISTORY VIEWED';
  public static readonly MEMBER_DETAILs_VIEWED = 'MEMBER DETAILS VIEWED';
  public static readonly FIND_PHARMACY_LIST_VIEWED = 'FIND PHARMACY LIST VIEWED';
  public static readonly PRESCRIPTIONS_HISTORY_VIEWED = 'PRESCRIPTIONS HISTORY VIEWED';
  public static readonly PRIVACY_LOCK_SCREEN = 'BIOMETRIC AUTH SUCCESS';
  public static readonly UPDATE_PROFILE_VIEWED = 'UPDATE PROFILE VIEWED';
  public static readonly LOGIN_SUCCESSFUL = 'LOGIN ATTEMPT SUCCESSFUL';
  public static readonly LOGIN_FORM_VIEWED = 'LOGIN FORM VIEWED';
  public static readonly SETTINGS_ADDRESS_VIEWED = 'SETTINGS ADDRESS LIST VIEWED';
  public static readonly ADD_ADDRESS_FORM_VIEWED = 'ADD NEW ADDRESS FORM VIEWED';
  public static readonly ADD_CREDIT_CARD_FORM_VIEWED = 'ADD NEW PAYMENT FORM VIEWED';
  public static readonly ADD_ORDER_FORM_VIEWED = 'ADD NEW ORDER FORM VIEWED';
  public static readonly SETTINGS_PAYMENT_LIST_VIEWED = 'SETTINGS PAYMENT METHODS VIEWED';
  public static readonly ADD_PAYMENT_FORM_VIEWED = 'ADD NEW PAYMENT FORM VIEWED';
  public static readonly SETTINGS_CHANGE_LANGUAGE_VIEWED = 'SETTINGS CHANGE LANGUAGE SCREEN VIEWED';
  public static readonly SELECT_LANGUAGE_VIEWED = 'SELECT LANGUAGE SCREEN VIEWED';
  public static readonly DRUG_INFORMATION_VIEWED = 'DRUG INFORMATION VIEWED';
  public static readonly CUSTOMER_SUPPORT_VIEWED = 'CUSTOMER SUPPORT VIEWED';
  public static readonly PREFERRED_MEDICATION_PDF = 'MEDICATION PDF VIEWED';
  public static readonly PUBLIC_FORM_PDF = 'PUBLIC FORM PDF VIEWED';
  public static readonly FORGOT_USERNAME_PAGE_VIEWED = 'FORGOT USERNAME PAGE VIEWED';
  public static readonly USERNAME_SENT_VIA_EMAIL = 'USERNAME SENT VIA EMAIL';
  public static readonly FORGOT_USERNAME_OK_CLICKED = 'FORGOT USERNAME OK CLICKED';
  public static readonly FORGOT_USERNAME_ERROR = 'FORGOT USERNAME ERROR';
  public static readonly ACCOUNT_PROFILE_VIEWED = 'ACCOUNT PROFILE VIEWED';
  public static readonly COMMON_API_CALL = 'API CALL';
  public static readonly CLINICAL_REVIEW_VIEWED = 'CLINICAL REVIEW VIEWED';
  public static readonly CART_CONFIRMATION_VIEWED_API_CALL = 'CART CONFIRMATION VIEWED API CALL';
  public static readonly CLINICAL_NOTIFICATIONS_VIEWED_API_CALL = 'CLINICAL NOTIFICATIONS VIEWED API CALL';
  public static readonly ORDER_NOTIFICATIONS_VIEWED_API_CALL = 'ORDER NOTIFICATIONS VIEWED API CALL';
  public static readonly PDF_VIEWED = 'PDF VIEWED';
  public static readonly PHI_SETTINGS_VIEWED_API_CALL = 'PHI SETTINGS VIEWED API CALL';
  public static readonly PLANNED_OUTAGE_MSG_DISPLAYED = 'PLANNED OUTAGE MSG VIEWED';
  public static readonly PLANNED_OUTAGE_MSG_CLOSED = 'PLANNED OUTAGE POPUP CLOSED';
  public static readonly UPDATE_CARD_ID_ATTEMPT = 'UPDATE CARD ID ATTEMPT';
  public static readonly UPDATE_CARD_ID_ATTEMPT_FAILED = 'UPDATE CARD ID FAILED';

  public static readonly GUEST_HOME_VIEWED = 'GUEST SCREEN VIEWED'
  public static readonly GUEST_HOME_PDF_VIEWED = 'GUEST SCREEN PDF VIEWED'
  public static readonly GUEST_HOME_CHANGE_LANGUAGE_CLICKED = 'GUEST SCREEN CHANGE LANGUAGE CLICKED'
  public static readonly GUEST_HOME_FAQ_CLICKED = 'GUEST SCREEN FAQ CLICKED';
  public static readonly GUEST_HOME_PRIVACY_POLICY_CLICKED = 'GUEST SCREEN PRIVACY POLICY CLICKED'
  public static readonly GUEST_HOME_ABOUT_US_CLICKED = 'GUEST SCREEN ABOUT US CLICKED';
  public static readonly GUEST_HOME_TERMS_OF_USE_CLICKED = 'GUEST SCREEN TERMS OF USE CLICKED';
  public static readonly GUEST_HOME_LOGIN_CLICKED = 'GUEST SCREEN- LOGIN CLICKED';
  public static readonly GUEST_HOME_CREATE_ACCOUNT_CLICKED = 'GUEST SCREEN- CREATE AN ACCOUNT CLICKED';
  public static readonly SSO_INITIATE_MEMBER_LOGIN = 'SSO- INITIATE MEMBER LOGIN';
  public static readonly SSO_PRE_COMPLETE_PROFILE_DATA_LOADED = 'SSO- PRE COMPLETE PROFILE DATA LOADED';
  public static readonly SSO_PRE_COMPLETE_PROFILE_NO_SSO_TOKEN = 'SSO- PRE COMPLETE PROFILE NO SSO TOKEN';
  public static readonly PRE_COMPLETE_PROFILE_PAGE_VIEWED = 'PRE COMPLETE PROFILE PAGE VIEWED';
  public static readonly SSO_COMPLETE_PROFILE_PAGE_VIEWED = 'SSO- COMPLETE PROFILE PAGE VIEWED';
  public static readonly SSO_CONTACT_DETAILS_FETCHED = 'SSO- CONTACT DETAILS FETCHED';
  public static readonly SSO_USERNAME_GENERATED = 'SSO- USERNAME GENERATED';
  public static readonly SSO_COMPLETE_PROFILE_REGISTER_BUTTON_CLICKED = 'SSO- COMPLETE PROFILE REGISTER BUTTON CLICKED';
  public static readonly SSO_COMPLETE_PROFILE_REGISTER_API_CALL = 'SSO- COMPLETE PROFILE REGISTER API CALL';
  public static readonly SSO_COMPLETE_PROFILE_REGISTER_API_ERROR = 'SSO- COMPLETE PROFILE REGISTER API ERROR';
  public static readonly SSO_USER_LOGGED_IN = 'SSO- USER LOGGED IN';

  public static readonly PATIENT_ENROLLMENT_FORM_VIEWED = 'MAIL ORDER PATIENT ENROLLMENT- FORM VIEWED';
  public static readonly PATIENT_ENROLLMENT_PRIVACY_POLICY_CLICKED = 'MAIL ORDER PATIENT ENROLLMENT- PRIVACY POLICY CLICKED';
  public static readonly PATIENT_ENROLLMENT_BILL_RIGHTS_CLICKED = 'MAIL ORDER PATIENT ENROLLMENT- PATIENT BILL OF RIGHTS CLICKED';
  public static readonly UPDATE_PATIENT_ENROLLMENT_DIALOG_VIEWED = 'MAIL ORDER PATIENT ENROLLMENT- UPDATE PROFILE DIALOG VIEWED';
  public static readonly PATIENT_ENROLLMENT_FORM_SUBMIT_CLICKED = 'MAIL ORDER PATIENT ENROLLMENT- SUBMIT BUTTON CLICKED';
  public static readonly PATIENT_ENROLLMENT_FORM_SIGNATURE_CHANGED = 'MAIL ORDER PATIENT ENROLLMENT- SIGNATURE CHANGED';
  public static readonly FAMILY_PROFILE_UPDATE_PROFILE_CLICKED = 'FAMILY PROFILE UPDATE PROFILE CLICKED';
  public static readonly HOME_SCREEN_CLIENT_MESSAGE_DISPLAYED = 'HOME SCREEN CLIENT MESSAGE DISPLAYED';

  public static readonly GET_CLIENT_EFFECTIVE_DATE_API_SUCCESS = "GET CLIENT EFFECTIVE DATE API SUCCESS";
  public static readonly GET_CLIENT_EFFECTIVE_DATE_API_ERROR = "GET CLIENT EFFECTIVE DATE API ERROR";

  public static readonly ORDERS_REQUEST_HISTORIC_DATA_BUTTON_CLICKED ="ORDERS- REQUEST HISTORIC DATA BUTTON CLICKED";
  public static readonly CLAIMS_REQUEST_HISTORIC_DATA_BUTTON_CLICKED ="CLAIMS- REQUEST HISTORIC DATA BUTTON CLICKED";
  public static readonly PRESCRIPTIONS_REQUEST_HISTORIC_DATA_BUTTON_CLICKED ="PRESCRIPTIONS- REQUEST HISTORIC DATA BUTTON CLICKED";
  
  public static readonly ORDERS_REQUEST_HISTORIC_DATA_API_SUCCESS = "ORDERS- REQUEST HISTORIC DATA API SUCCESS";
  public static readonly ORDERS_REQUEST_HISTORIC_DATA_API_ERROR ="ORDERS- REQUEST HISTORIC DATA API ERROR";
  
  public static readonly CLAIMS_REQUEST_HISTORIC_DATA_API_SUCCESS ="CLAIMS- REQUEST HISTORIC DATA API SUCCESS";
  public static readonly CLAIMS_REQUEST_HISTORIC_DATA_API_ERROR ="CLAIMS- REQUEST HISTORIC DATA API ERROR";
 
  public static readonly PRESCRIPTIONS_REQUEST_HISTORIC_DATA_API_SUCCESS ="PRESCRIPTIONS- REQUEST HISTORIC DATA API SUCCESS";
  public static readonly PRESCRIPTIONS_REQUEST_HISTORIC_DATA_API_ERROR ="PRESCRIPTIONS- REQUEST HISTORIC DATA API ERROR";
  
  public static readonly ORDERS_FILTER_BY_DATE_PAST6 ="ORDERS- FILTER BY DATE - PAST 6-12 MONTH";
  public static readonly ORDERS_FILTER_BY_DATE_OLDER_THAN_YEAR ="ORDERS- FILTER BY DATE - OLDER THAN YEAR";
  public static readonly ORDERS_FILTER_BY_DATE_ALL ="ORDERS- FILTER BY DATE - All";  
  public static readonly PRESCRIPTIONS_FILTER_BY_DATE_LAST_6MONTHS ="PRESCRIPTIONS- FILTER BY DATE - LAST 6 MONTHS";
  public static readonly PRESCRIPTIONS_FILTER_BY_DATE_LAST_ONE_YEAR ="PRESCRIPTIONS- FILTER BY DATE - LAST ONE YEAR";
  public static readonly PRESCRIPTIONS_FILTER_BY_DATE_ALL ="PRESCRIPTIONS- FILTER BY DATE - ALL";
  
  public static readonly REQUEST_HISTORIC_DATA_FORM_VIEWED = "REQUEST HISTORIC DATA FORM VIEWED";
  public static readonly REQUEST_HISTORIC_DATA_FORM_SUBMIT_BUTTON_CLICKED = "REQUEST HISTORIC DATA FORM SUBMIT BUTTON CLICKED";
  public static readonly REQUEST_HISTORIC_DATA_FORM_CANCEL_BUTTON_CLICKED = "REQUEST HISTORIC DATA FORM CANCEL BUTTON CLICKED";

  public static readonly DELETE_MEMBER_ACCOUNT_PAGE_VIEWED = 'DELETE MEMBER ACCOUNT PAGE VIEWED';
  public static readonly DELETE_MEMBER_ACCOUNT_SEND_OTP_BUTTON_CLICKED = 'DELETE MEMBER ACCOUNT SEND OTP CLICKED';
  public static readonly DELETE_MEMBER_ACCOUNT_RE_SEND_OTP_BUTTON_CLICKED = 'DELETE MEMBER ACCOUNT RE-SEND OTP CLICKED';
  public static readonly DELETE_MEMBER_ACCOUNT_VERIFY_OTP_BUTTON_CLICKED = 'DELETE MEMBER ACCOUNT VERIFY OTP CLICKED';
  public static readonly DELETE_MEMBER_ACCOUNT_NEXT_BUTTON_CLICKED = 'DELETE MEMBER ACCOUNT NEXT BUTTON CLICKED';
  public static readonly DELETE_MEMBER_ACCOUNT_SUBMIT_DELETE_BUTTON_CLICKED = 'DELETE MEMBER ACCOUNT SUBMIT DELETE CLICKED';

  public static readonly DELETE_MEMBER_ACCOUNT_SEND_OTP_SUCCESS = 'DELETE MEMBER ACCOUNT SEND OTP SUCCESS';
  public static readonly DELETE_MEMBER_ACCOUNT_SEND_OTP_ERROR = 'DELETE MEMBER ACCOUNT SEND OTP ERROR';
  public static readonly DELETE_MEMBER_ACCOUNT_RESEND_OTP_SUCCESS = 'DELETE MEMBER ACCOUNT RESEND OTP SUCCESS';
  public static readonly DELETE_MEMBER_ACCOUNT_RESEND_OTP_ERROR = 'DELETE MEMBER ACCOUNT RESEND OTP ERROR';
  public static readonly DELETE_MEMBER_ACCOUNT_VERIFY_OTP_SUCCESS = 'DELETE MEMBER ACCOUNT VERIFY OTP SUCCESS';
  public static readonly DELETE_MEMBER_ACCOUNT_VERIFY_OTP_ERROR = 'DELETE MEMBER ACCOUNT VERIFY OTP ERROR';
  public static readonly DELETE_MEMBER_ACCOUNT_SUBMIT_DELETE_MEMBER_SUCCESS = 'DELETE MEMBER ACCOUNT SUBMIT DELETE MEMBER SUCCESS';
  public static readonly DELETE_MEMBER_ACCOUNT_SUBMIT_DELETE_MEMBER_ERROR = 'DELETE MEMBER ACCOUNT SUBMIT DELETE MEMBER ERROR';

  public static readonly PRESCRIPTIONS_LIST_UPDATED = "PRESCRIPTIONS LIST UPDATED";
  public static readonly ORDERS_LIST_UPDATED = "ORDERS LIST UPDATED";
  public static readonly CLAIMS_LIST_UPDATED = "CLAIMS LIST UPDATED"

  public static readonly SET_AUTO_REFILL_CLICK = "PRESCRIPTIONS - SET AUTO REFILL CLICK";
  public static readonly SET_AUTO_REFILL_API_SUCCESS = "PRESCRIPTIONS - SET AUTO REFILL API SUCCESS";
  public static readonly SET_AUTO_REFILL_API_ERROR = "PRESCRIPTIONS - SET AUTO REFILL API ERROR";

  public static readonly PATIENT_ENROLLMENT_FORM_CREATE_API_SUCCESS = 'MAIL ORDER PATIENT ENROLLMENT- CREATE PROFILE API SUCCESS';
  public static readonly PATIENT_ENROLLMENT_FORM_CREATE_API_ERROR = 'MAIL ORDER PATIENT ENROLLMENT- CREATE PROFILE ERROR';

  public static readonly HOME_SCREEN_PRESCRIPTION_VIEW_HISTORY_CLICKED = 'HOME SCREEN PRESCRIPTION - VIEW HISTORY CLICKED';
  public static readonly HOME_SCREEN_PRESCRIPTION_VIEW_ALL_CLICKED = 'HOME SCREEN PRESCRIPTION - VIEW ALL CLICKED';
  public static readonly HOME_SCREEN_CLAIMS_VIEW_ALL_CLICKED = 'HOME SCREEN CLAIMS - VIEW ALL CLICKED';
  public static readonly HOME_SCREEN_ORDERS_VIEW_ALL_CLICKED = 'HOME SCREEN ORDERS - VIEW ALL CLICKED';
 
  public static readonly REFILL_REMINDER_CLICKED = 'PRESCRIPTIONS -  REFILL REMINDER CLICKED';
  public static readonly AUTO_CHARGE_SHIP_CLICKED = 'PRESCRIPTIONS - AUTO REFILL CHARGE SHIP CLICKED';
  public static readonly AUTO_CHARGE_SHIP_ATTESTATION_VIEWED = 'AUTO CHARGE SHIP ATTESTATION - VIEWED';
  public static readonly AUTO_CHARGE_SHIP_ATTESTATION_CONFIRMED = 'AUTO CHARGE SHIP ATTESTATION - CONFIRM BUTTON CLICKED';
  public static readonly AUTO_CHARGE_SHIP_ATTESTATION_CANCELLED = 'AUTO CHARGE SHIP ATTESTATION - CANCEL BUTTON CLICKED';
  public static readonly REFILL_REMINDER_CONFIRM_BUTTON_CLICKED = 'REFILL REMINDER CONFIRMATION - CONFIRM BUTTON CLICKED';
  public static readonly REFILL_REMINDER_CANCEL_BUTTON_CLICKED = 'REFILL REMINDER CONFIRMATION - CANCEL BUTTON CLICKED';

  public static readonly PAYMENT_CARD_EXPIRY_EDIT_CLICKED = 'PAYMENT - CARD EXPIRY EDIT CLICKED';
  public static readonly PAYMENT_CARD_EXPIRY_EDIT_CONFIRMED = 'PAYMENT - CARD EXPIRY EDIT CONFIRMED';
  public static readonly PAYMENT_CARD_EXPIRY_EDIT_CANCELLED = 'PAYMENT - CARD EXPIRY EDIT CANCELLED';
  public static readonly PAYMENT_CARD_EDIT_API_SUCCESS = 'PAYMENT - EDIT CARD API SUCCESS';
  public static readonly PAYMENT_CARD_EDIT_API_FAILED = 'PAYMENT - EDIT CARD API FAILED';

  public static readonly DRUG_SEARCH_BY_NAME_SUCCESS = 'DRUG SEARCH BY NAME SUCCESS';
  public static readonly DRUG_SEARCH_BY_NAME_NO_DATA_FOUND = "DRUG SEARCH BY NAME NO DATA FOUND";
  public static readonly DRUG_SEARCH_BY_NAME_ERROR = 'DRUG SEARCH BY NAME ERROR';
  public static readonly DRUG_SEARCH_SELECTED_DRUG_NAME_SUCCESS = 'DRUG SEARCH SELECTED DRUG NAME SUCCESS';
  public static readonly DRUG_SEARCH_SELECTED_DRUG_NAME_ERROR = 'DRUG SEARCH SELECTED DRUG NAME ERROR';
  
  public static readonly DRUG_PRICING_SEARCH_BY_ZIP_OR_CITY_SUCCESS = "DRUG PRICING SEARCH BY ZIP OR CITY SUCCESS";
  public static readonly DRUG_PRICING_SEARCH_BY_ZIP_OR_CITY_NO_DATA_FOUND= "DRUG PRICING SEARCH BY ZIP OR CITY NO DATA FOUND";
  public static readonly DRUG_PRICING_SEARCH_BY_ZIP_OR_CITY_ERROR = "DRUG PRICING SEARCH BY ZIP OR CITY ERROR";
 
  public static readonly PHARMACY_SEARCH_BY_ZIP_OR_CITY_SUCCESS = "PHARMACY SEARCH BY ZIP OR CITY SUCCESS";
  public static readonly PHARMACY_SEARCH_BY_ZIP_OR_CITY_NO_DATA_FOUND = "PHARMACY SEARCH BY ZIP OR CITY NO DATA FOUND";
  public static readonly PHARMACY_SEARCH_BY_ZIP_OR_CITY_ERROR = "PHARMACY SEARCH BY ZIP OR CITY ERROR";
  
  public static readonly MAIL_ORDER_FAQ_SEARCH_RESULT = 'MAIL ORDER FAQ SEARCH RESULT';
  public static readonly MAIL_ORDER_FAQ_SEARCH_NO_DATA_FOUND = 'MAIL ORDER FAQ SEARCH NO DATA FOUND';

  public static readonly BENEFITS_FAQ_SEARCH_RESULT = 'BENEFITS FAQ SEARCH RESULT';
  public static readonly BENEFITS_FAQ_SEARCH_NO_DATA_FOUND = 'BENEFITS FAQ SEARCH NO DATA FOUND';
  
  public static readonly CLINICAL_REVIEW_FAQ_SEARCH_RESULT = 'CLINICAL REVIEW FAQ SEARCH RESULT';
  public static readonly CLINICAL_REVIEW_FAQ_SEARCH_NO_DATA_FOUND = 'CLINICAL REVIEW FAQ SEARCH NO DATA FOUND';

  public static readonly GET_CLAIM_API_SUCCESS = "GET CLAIM API SUCCESS";
  public static readonly GET_CLAIM_API_ERROR = "GET CLAIM API ERROR";

  public static readonly CLINICAL_REVIEW_API_SUCCESS = "CLINICAL REVIEW API SUCCESS";
  public static readonly CLINICAL_REVIEW_API_ERROR = "CLINICAL REVIEW API ERROR"; 

  public static readonly PRESCRIPTION_ADDED_TO_CART = "PRESCRIPTION ADDED TO CART";

  public static readonly PRESCRIPTION_REMOVE_FROM_CART = "PRESCRIPTION REMOVE FROM CART";
  public static readonly CART_CHECKOUT_BUTTON_CLICKED = "CART CHECKOUT BUTTON CLICKED";

  public static readonly CART_FLOW_HOME_DELIVERY_BUTTON_CLICKED = "CART FLOW HOME DELIVERY BUTTON CLICKED";
  public static readonly CART_FLOW_SHIPPING_ADDRESS_VIEWED = "CART FLOW SHIPPING ADDRESS VIEWED";
  public static readonly CART_FLOW_SHIPPING_ADDRESS_API_SUCCESS = "CART FLOW SHIPPING ADDRESS API SUCCESS";
  public static readonly CART_FLOW_SHIPPING_ADDRESS_API_ERROR = "CART FLOW SHIPPING ADDRESS API ERROR";
  public static readonly CART_FLOW_SHIPPING_ADDRESS_CONTINUE_CLICKED = "CART FLOW SHIPPING ADDRESS CONTINUE CLICKED" ;
  public static readonly CART_FLOW_ADD_SHIPPING_ADDRESS_BUTTON_CLICKED = "CART FLOW ADD SHIPPING ADDRESS BUTTON CLICKED";
  public static readonly CART_FLOW_PAYMENT_METHOD_VIEWED = "CART FLOW PAYMENT METHOD VIEWED";
  public static readonly CART_FLOW_PAYMENT_CREDIT_CARD_API_SUCCESS = "CART FLOW PAYMENT CREDIT CARD SUCCESS";
  public static readonly CART_FLOW_PAYMENT_CREDIT_CARD_API_ERROR = "CART FLOW PAYMENT CREDIT CARD ERROR" ;
  public static readonly CART_FLOW_PAYMENT_CONTINUE_CLICKED = "CART FLOW PAYMENT CONTINUE CLICKED";
  public static readonly CART_FLOW_ADD_NEW_PAYMENT_BUTTON_CLICKED = "CART FLOW ADD NEW PAYMENT BUTTON CLICKED";
  public static readonly CART_FLOW_CONFIRM_ORDER_VIEWED = "CART FLOW CONFIRM ORDER VIEWED";
  public static readonly CART_FLOW_CANCEL_BUTTON_CLICKED = "CART FLOW CANCEL BUTTON CLICKED";
  
}

export class AppCenterAnalyticsAPIMsgs {
  public static readonly COMMON_INITIAL_MSG = 'Response text not updated';
  public static readonly SUCCESS_ADD_SHIPPING_ADDRESS = 'Shipping address added successfully';
  public static readonly ERROR_ADD_SHIPPING_ADDRESS = 'Something went wrong';
  public static readonly SUCCESS_ADD_PAYMENT_METHOD = 'Payment method added successfully';
  public static readonly ERROR_ADD_PAYMENT_METHOD = 'Something went wrong';
  public static readonly SUCCESS_UPDATE_PROFILE = 'Profile updated successfully';
  public static readonly ERROR_UPDATE_PROFILE = 'Something went wrong';
  public static readonly SUCCESS_ORDER_PLACEMENT = 'Order placed successfully'
  public static readonly ERROR_ORDER_PLACEMENT = 'Something went wrong';
  public static readonly SUCCESS_NOTIFICATIONS_UPDATE = 'Notifications updated successfully'
  public static readonly ERROR_NOTIFICATIONS_UPDATE = 'Something went wrong';
  public static readonly COMMON_ERROR_MSG = 'Something went wrong';
  public static readonly PHI_SETTING_UPDATE_SUCCESS = 'PHI settings updated successfully';
  public static readonly PHI_SETTING_UPDATE_ERROR = 'Something went wrong';
  public static readonly PATIENT_ENROLLMENT_PROFILE_UPDATED = 'Mail Order profile updated successfully';
  public static readonly SSO_MEMBER_REGISTERED = 'SSO member registered successfully';
}
