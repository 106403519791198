/**
 * @file cms.service.ts
 */
import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Link } from 'src/app/interfaces/link.interface';
import { environment } from 'src/environments/environment';
import { AnswerSet } from '../../interfaces/answer-set.interface';
import { MultilingualService } from '../../core/services/multilingual/multilingual.service';
import { Term } from '../../interfaces/term.interface';
import { Documents } from 'src/app/interfaces/get-documents.interface';
import { CustomerSupport } from 'src/app/interfaces/customer-support-response.interface';
import { UserService } from '../user/user.service';
import { MaintenanceMode } from 'src/app/interfaces/maintenance-mode.interface';
import { TokenService } from 'src/app/core/services/token/token.service';
import { PharmacySearchConfig } from 'src/app/interfaces/pharmacy-search-config.interface';
import {
  ClientConfigurations,
  CMS_CONFIG_KEYS_ENUM,
  CMSConfig,
} from 'src/app/interfaces/cms-configuration-response.enum';
import { searchClientId } from 'src/app/shared/util';
@Injectable({
  providedIn: 'root',
})
export class CmsService {
  answerSets: AnswerSet[] = [];

  private cmsConfigCache$!: Observable<CMSConfig>;

  constructor(
    private readonly http: HttpClient,
    private readonly multilingualService: MultilingualService,
    private readonly userService: UserService,
    private readonly tokenService: TokenService
  ) {}

  public loadConfigurations(clientId: string): Observable<CMSConfig> {
    if (!this.cmsConfigCache$) {
      this.cmsConfigCache$ = this.getCombinedCmsConfiguration(clientId).pipe(
        shareReplay(1)
      );
    }
    return this.cmsConfigCache$;
  }

  public getCombinedCmsConfiguration(clientId: string): Observable<CMSConfig> {
    return combineLatest([
      this.getLinks(),
      this.getConfigurationSettings(),
      this.getPharmacySearchConfiguration(),
    ]).pipe(
      map(([links, commonConfig, pharmacySearchConfig]) => {
        const config: CMSConfig = {
          links: links,
          commonConfig: commonConfig,
          pharmacySearchConfig: this.getPharmacySearchConfig(
            pharmacySearchConfig,
            clientId
          ),
          clientSpecificConfig: this.fetchClientConfigurations(
            commonConfig,
            clientId
          ),
        };
        // TODO: remove comment after testing
        // console.log('Configs: ', config);
        return config;
      })
    );
  }

  fetchClientConfigurations(
    commonConfig: Record<string, string>,
    clientId: string
  ) {
    const clientConfigs: ClientConfigurations = {
      clientBannerMessage: this.getClientMessage(clientId, commonConfig),
      disableDigitalIDCards: this.isClientConfiguredByKey(
        commonConfig,
        clientId,
        'DISABLE_DIGITAL_ID_CARDS'
      ),
      enableAutoChargeShip: this.isClientConfiguredByKey(
        commonConfig,
        clientId,
        'ENABLE_AUTO_REFILL_CHARGE_SHIP'
      ),
      hideOutOfNetwork: this.isClientConfiguredByKey(
        commonConfig,
        clientId,
        'HIDE_OUT_OF_NETWORK_PHARMACIES'
      ),
      suppressMailOrderFeatures: this.isClientConfiguredByKey(
        commonConfig,
        clientId,
        'SUPPRESS_MAIL_ORDER_FOR_CLIENTS'
      ),
      enablePMartForm: this.isClientConfiguredByKey(
        commonConfig,
        clientId,
        'BENECARD_CLIENTS_TO_ENABLE_PMART_FORM'
      ),
      enableBannerMessage: {
        commonHeader: this.isClientConfiguredByKey(
          commonConfig,
          clientId,
          'ENABLE_COMMON_HEADER_BANNER_MESSAGE'
        ),
        benefitScreen: this.isClientConfiguredByKey(
          commonConfig,
          clientId,
          'ENABLE_BENEFIT_BANNER_MESSAGE'
        ),
        mailOrderScreen: this.isClientConfiguredByKey(
          commonConfig,
          clientId,
          'ENABLE_MAIL_ORDERS_BANNER_MESSAGE'
        ),
        orderCheckoutScreen: this.isClientConfiguredByKey(
          commonConfig,
          clientId,
          'ENABLE_ORDER_CHECKOUT_BANNER_MESSAGE'
        ),
      },
    };
    return clientConfigs;
  }

  getLinks(): Observable<Link[]> {
    return this.http
      .get<Link[]>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/empirix-links`
      )
      .pipe(catchError(() => of([])));
  }

  getEmpiRxFAQ(): Observable<AnswerSet[]> {
    return this.http
      .get<AnswerSet[]>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/faqs/${this.multilingualService.selectedLang}`
      )
      .pipe(catchError(() => of([])));
  }

  getBenefitsFAQ(): Observable<AnswerSet[]> {
    return this.http
      .get<AnswerSet[]>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/benefits-page-faqs/en`
      )
      .pipe(catchError(() => of([])));
  }

  geClinicalReviewFAQ(): Observable<AnswerSet[]> {
    return this.http
      .get<AnswerSet[]>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/clinical-review-faqs/en`
      )
      .pipe(catchError(() => of([])));
  }

  getMailOrderFAQ(): Observable<AnswerSet[]> {
    return this.http
      .get<AnswerSet[]>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/mail-order-faqs/en`
      )
      .pipe(catchError(() => of([])));
  }

  getPrivacyPolicy(): Observable<Term[]> {
    return this.http.get<Term[]>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/empirx-health-privacy-statement/${this.multilingualService.selectedLang}`
    );
  }

  getTermsOfUse(): Observable<Term[]> {
    return this.http.get<Term[]>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/terms-of-use/${this.multilingualService.selectedLang}`
    );
  }

  getDocuments(): Observable<Documents[]> {
    const providerType = this.tokenService.getProviderType();
    return this.http.get<Documents[]>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/pdf-download/?Providertype=${providerType}`
    );
  }

  getCustomerSupport(): Observable<CustomerSupport[]> {
    return this.http.get<CustomerSupport[]>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/customer-support/${this.multilingualService.selectedLang}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}`
    );
  }

  getConfigurationSettings(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/configurationapi/${this.multilingualService.selectedLang}`
      )
      .pipe(catchError(() => of({})));
  }

  getMaintenanceMode(): Observable<MaintenanceMode[]> {
    return this.http.get<MaintenanceMode[]>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/maintenance-mode`
    );
  }

  getAutoRefillAttestation(): Observable<{
    title: string;
    attestationHtmlText: string;
  }> {
    return this.http.get<{ title: string; attestationHtmlText: string }>(
      `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/auto-refill-attestation/${this.multilingualService.selectedLang}`
    );
  }

  private getPharmacySearchConfiguration(): Observable<PharmacySearchConfig[]> {
    // TODO: Remove hardcoded response
    const result: PharmacySearchConfig[] = [
      {
        name: 'Giant Eagle Pharmacy',
        clientIds: '28001, 19000',
        pharmacyChainCode: '248',
        defaultSearchRadius: '30',
        pharmacySearchAPI:
          '/member-service/member-service/rest/giant-eagle-pharmacies',
        drugPricingAPI:
          '/member-service/member-service/rest/giant-eagle-drug-pricing',
        translations: {
          NO_PHARMACIES_FOUND_EN:
            'Sorry, no Giant Eagle Pharmacies found within your search criteria. <br/> If you would like to view additional pharmacies, please ',
          NO_PHARMACIES_FOUND_ES:
            'Es: Sorry, no Giant Eagle Pharmacies found within your search criteria. <br/> If you would like to view additional pharmacies, please ',
          NO_PRICING_FOUND_EN:
            'Sorry, no Giant Eagle pharmacies found within your search criteria.<br/> If you would like to view additional pharmacy pricing, please ',
          NO_PRICING_FOUND_ES:
            'Es: Sorry, no Giant Eagle pharmacies found within your search criteria.<br/> If you would like to view additional pharmacy pricing, please ',
          VIEW_OTHER_PHARMACIES_EN: 'View additional pharmacy pricing',
          VIEW_OTHER_PHARMACIES_ES: 'Es: View additional pharmacy pricing',
          OTHER_PHARMACIES_LIST_DISPLAYED_EN:
            'Showing results for Non-Giant Eagle pharmacies.',
          OTHER_PHARMACIES_LIST_DISPLAYED_ES:
            'Es: Showing results fr Non-Giant Eagle pharmacies.o',
        },
      },
    ];
    // return of(result);

    return this.http
      .get<PharmacySearchConfig[]>(
        `${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/pharmacy-search-configuration`
      )
      .pipe(catchError(() => of([])));
  }

  /**
   * Checks if members client id is configured in WP under the input key
   * @param clientId: Client ID to check in the configuration
   * @returns
   */
  public isClientConfiguredByKey(
    configurations: Record<string, string>,
    clientId: string,
    key: keyof typeof CMS_CONFIG_KEYS_ENUM
  ): boolean {
    try {
      if (configurations && Object.keys(configurations).includes(key)) {
        let clients: string = configurations[key];
        return searchClientId(clients, clientId);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  private getPharmacySearchConfig(
    pharmacySearchConfig: PharmacySearchConfig[],
    memberClientId: string
  ): PharmacySearchConfig | null {
    let clientConfiguration: PharmacySearchConfig | null = null;

    const clientIndex = (pharmacySearchConfig || []).findIndex((x) =>
      searchClientId(x.clientIds, memberClientId)
    );

    if (clientIndex != -1) {
      // get the configuration against client ID
      clientConfiguration = pharmacySearchConfig[clientIndex];
    }
    return clientConfiguration;
  }

  private getClientMessage(
    clientId: string,
    commonConfig: Record<string, string>
  ): string {
    try {
      if (commonConfig && Object.keys(commonConfig).includes(clientId)) {
        if (commonConfig[clientId]) {
          return commonConfig[clientId];
        } else {
          return '';
        }
      }
    } catch (error) {
      return '';
    }
    return '';
  }
}
