import { APIVersionConfigType } from './api-version-config';

export const APIVersionConfig: APIVersionConfigType = {
  defaultVersion: '1.0',

  // overrides
  versionOverrides: {
    '/Benecard/mail-order-refills/mail-order-refills/rest/prescriptions': {
      methods: ['GET'],
      apiVersion: '2.0',
    },
    '/mail-order/set-auto-refill': {
      methods: ['POST'],
      apiVersion: '2.0',
    },
    '/Benecard/member-service/member-service/rest/family-coverage': {
      apiVersion: '2.0',
    },
  },
};
