export type EnumKeyValueTypes = string | number;

export type EnumType = {
  [key in EnumKeyValueTypes]: EnumKeyValueTypes;
};

export function getISODate(): string {
  return new Date().toISOString();
}

export function getEnumStringKeys(enumInstance: EnumType): string[] {
  return Object.keys(enumInstance)
    .filter((key) => typeof enumInstance[key] === 'string')
    .map((key) => enumInstance[key] as string);
}

export function getEnumNumberKeys(enumInstance: EnumType): number[] {
  return Object.keys(enumInstance)
    .filter((key) => typeof enumInstance[key] === 'number')
    .map((key) => enumInstance[key] as number);
}

export function calculateAge(dob: string | Date): number {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // If the birth month hasn't occurred yet this year, subtract 1 from age
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

export function clearPhoneFormat(formattedPhone: string): string {
  if (formattedPhone)
    return formattedPhone.replace(/\D/g, ''); // Remove non-numeric characters
  else return '';
}

/**
 * 
 * @param itemsArr: Array of type T
 * @param indexToMove: Value at indexToMove need to be moved at the top of array
 * @returns the updated array by moving item to the top
 */
export function moveItemToTop<T>(
  itemsArr: Array<T>,
  indexToMove: number
): Array<T> {
  if (indexToMove < 0) return itemsArr;

  // Get the item at the indexToMove
  const item = itemsArr[indexToMove];
  
  // Remove the item at the indexToMove
  itemsArr.splice(indexToMove, 1);

  // Insert the element at the start of array
  itemsArr.unshift(item);
  return itemsArr;
}

/**
 * Search client ID in the comma separated list of client Ids.
 * @param commaSeparatedClientIds . Example: "28001, 29000, 19000"
 * @param clientIdToSearch: 19000
 * @returns 
 */
export function searchClientId(commaSeparatedClientIds: string, clientIdToSearch: string): boolean {
  if (commaSeparatedClientIds) {
    const clientsList = commaSeparatedClientIds.split(',').map((x) => x.trim());
    if (clientIdToSearch && clientsList && clientsList.length > 0) {
      return clientsList.includes(clientIdToSearch);
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * Returns last N charts from string
 * @param str 
 * @param noOfChars 
 * @returns 
 */
export function getLastNChars(str: string, noOfChars: number): string {
  if (str) {
    return str.substr(str.length - noOfChars);
  } else {
    return str;
  }
}
